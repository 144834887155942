<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Список заявок / займов с удаленной КИ</template>
        <template #content>
          <AbstractTable ref="dataTable" code="deleteActions" display-filter="menu">
            <template #id="row">
              <IdChip
                  :id="row.data.id"
                  :menu-items="generateItems(row.data)"
                  toast-template='ID заявки / займа с удаленной КИ "{v}" скопирован'
              />
            </template>
          </AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import IdChip from "@/components/Common/IdChip.vue";
import DeleteActionReportsTableDialog from "@/components/Dialog/DeleteActionReportsTableDialog.vue";
export default {
  name: "DeletedList",
  components: {IdChip, AbstractTable},
  data() {
    return {
      currentDialog: null
    }
  },
  beforeUnmount(){
    if (this.currentDialog !== null) {
      this.currentDialog.close();
    }
  },
  methods: {
    generateItems(data) {
      return [
        {
          label: 'Отчёты с этой заявкой / займом',
          icon: 'pi pi-fw pi-list',
          command: () => this.showRelatedReports(data),
        }
      ]
    },
    showRelatedReports(data) {
      this.currentDialog = this.$dialog.open(DeleteActionReportsTableDialog, {
        data: {
          deleteActionId: data.id
        },
        props: {
          style: {
            width: '100%',
            maxWidth: '1400px'
          },
          header: `Отчёты на удаление КИ с заявкой / займом '${data.applicationUuid}'`,
          modal: true,
          closable: true,
          dismissableMask: true
        },
        onClose: () => {
          this.currentDialog = null
        }
      })
    },
    refreshTable() {
      this.$refs.dataTable.getData()
    }
  }
}
</script>

<style scoped>

</style>