<template>
  <DeleteReportsTable title="Список отчётов" :delete-action-id="deleteActionId"/>
</template>

<script>
import DeleteReportsTable from "@/components/tables/DeleteReportsTable.vue";

export default {
  name: 'DeleteActionReportsTableDialog',
  components: {DeleteReportsTable},
  inject: ['dialogRef'],
  data() {
    return {
      deleteActionId: null
    }
  },
  beforeMount() {
    const params = this.dialogRef.data;
    this.deleteActionId = params.deleteActionId;
  }
}
</script>